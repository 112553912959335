import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image"

import "../css/blogs.css"
import "../css/globals.css"
import { Link } from "gatsby"
import useIsMobile from "../hooks/useIsMobile"

export default function News(props) {
  const isMobile = useIsMobile("1024px")
  const data = props.pageContext.content

  var res = Object.keys(data).map(function (name) {
    var obj = {}
    obj[name] = data[name]
    return obj
  })

  useEffect(() => {
    let scrollListner
    if (!isMobile)
      scrollListner = window.addEventListener("scroll", handleScroll)

    return () => {
      if (!isMobile) window.removeEventListener("scroll", scrollListner)
    }
  }, [])

  const handleScroll = () => {
    let el = document.getElementById("sticky")

    if (document.getElementById("footer") && el) {
      let footerTop = document.getElementById("footer").offsetTop

      let nBottom =
        el.previousSibling.offsetHeight + el.previousSibling.offsetTop

      let windowTop = window.scrollY

      let foot = document.getElementById("footer").offsetHeight
      let x = footerTop - foot - el.offsetHeight / 2

      if (windowTop > nBottom && windowTop < x) {
        el.style.position = "fixed"
        el.style.width = `23%`
        el.style.top = "19vh"
      } else {
        el.style.position = "sticky"
        el.style.width = "100%"
      }
    }
  }

  let latestNewsArr = [
    {
      heading: "Padhne Ka Pressure Mat Lo, Plan Karo: Tata Studi",
      link: "padhne-ka-pressure-mat-lo-plan-karo",
    },
    {
      heading: "TATA ClassEdge Announces Launch Of New Self-Study App Studi",
      link: "self-study-app-studi",
    },
    {
      heading:
        "E-learning app Tata Studi launches first campaign for CBSE students",
      link:
        "e-learning-app-tata-studi-launches-first-campaign-for-cbse-students",
    },
    {
      heading:
        "Mullen Lintas Mumbai wins brand and creative communications mandate for Tata Studi",
      link:
        "mullen-lintas-mumbai-wins-brand-and-creative-communications-mandate-for-tata-studi",
    },
    {
      heading:
        "TATA STUDi Announces Launch Of Marketing Campaign ‘Padhne Ka Sahi Tareeka’",
      link: "padhne-ka-sahi-tareeka",
    },
  ]

  return (
    <Layout>
      {data.field_press_release_schema_scrip.processed && (
        <Helmet>
          <script type="application/ld+json">
            {`
              ${data.field_press_release_schema_scrip.processed}
        `}
          </script>
        </Helmet>
      )}

      <SEO
        title={data.field_meta_title}
        description={data.field_meta_desc}
        lang="en"
      />
      <div className="container-fluid blog-wrapper">
        <div className="row">
          <div className="col-lg-9 left-content">
            <div className="col-12">
              <div className="blog-heading font-samsung mt-4">{data.title}</div>
              <br />
              <div className="blog-date font-samsung color-blue">
                {data.field_in_the_news_date}
              </div>
              <div className="blog-banner text-center mx-auto">
                {props.pageContext.content.relationships
                  .field_in_the_news_banner_image ? (
                  <GatsbyImage
                    image={
                      props.pageContext.content.relationships
                        .field_in_the_news_banner_image.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    loading="lazy"
                    placeholder="tracedSVG"
                    formats={["webp"]}
                  />
                ) : (
                  <></>
                )}
              </div>
              <br />
              <div className="blog-content font-lato mt-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.field_content_intro.processed,
                  }}
                />
              </div>

              <div className="blog-content font-lato">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.field_content_end.processed,
                  }}
                />
              </div>
            </div>
          </div>

          {/* for latest news sidebar */}

          <div className="col-lg-3 right-content" id="sidebar">
            <div id="blog-page-sidebar">
              <div className="col-12">
                <div className="right-content-heading font-samsung mb-2 mt-4">
                  Latest News
                </div>
                <div className="top-stories-list">
                  {latestNewsArr.map((item, i) => {
                    return (
                      <div className="top-story" key={i}>
                        <Link to={`/resources/media/inthenews/${item.link}`}>
                          <div className="color-black font-lato">
                            <i className="fa fa-caret-right" /> {item.heading}{" "}
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
